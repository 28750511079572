<button id="applyBtn" [disabled]="btnDisabled" (click)="emitSubmitting($event)"
    class="da-btn menu next-btn"
    type="submit">
    {{ invalid ? onInvalidMessage : onValidMessage}}
    <!--<mat-icon *ngIf="showCheck && valid" aria-hidden="false" aria-label="Example arrow icon">{{btnIcon}}</mat-icon>-->
</button>

<div *ngIf="showTitle && (!btnDisabled && valid)" class="apply-btn-title">
    <span>
        Drücke <b>Enter</b>
    </span>
    <mat-icon>keyboard_return</mat-icon>
</div>