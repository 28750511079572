import { Injectable } from '@angular/core';

export declare type InitialSource = 'facebook' | 'google' | 'youtube' | 'youtube-ads' | 'tiktok' | 'unbekannt';

@Injectable({
  providedIn: 'root'
})
export class TrackingserviceService {
  initialSource: InitialSource = 'unbekannt';
  constructor() { }


  getInitialSource(): InitialSource {
    try {
      let url = window.location.href;

      if (localStorage.getItem('initialSource')) {
        return localStorage.getItem('initialSource') as InitialSource || 'unbekannt';
      }

      let initialSource: InitialSource = 'facebook';

      if (url.endsWith('/g') || url.endsWith('/g/') || url.endsWith('/info') || url.endsWith('/info/')) {
        initialSource = 'google';
      } else if (url.endsWith('/y') || url.endsWith('/y/')) {
        initialSource = 'youtube';
      }
      else if (url.endsWith('/ya') || url.endsWith('/ya/')) {
        initialSource = 'youtube-ads';
      }
      else if (url.endsWith('/t') || url.endsWith('/t/')) {
        initialSource = 'tiktok';
      }

      return initialSource;
    } catch (error) {
      return 'unbekannt';
    }
  }

  saveInitialSource() {
    try {
      localStorage.setItem('initialSource', this.getInitialSource());
    } catch (error) {
      this.initialSource = 'unbekannt';
    }
  }

  checkUrlParameters() {
    const params = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_id', 'utm_term', 'utm_content'];
    const foundParams: any = {};
    params.forEach(param => {
      const value = new URLSearchParams(window.location.search).get(param);
      foundParams[param] = value !== null;
    });
    return foundParams;
  }

  storeUTMParameters() {
    const params: any = this.checkUrlParameters();
    for (const [key, isPresent] of Object.entries(params)) {
      if (isPresent && !localStorage.getItem(key)) {
        localStorage.setItem(key, new URLSearchParams(window.location.search).get(key) || '');
      }
    }
  }
}
