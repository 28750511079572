import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplyService } from './apply/services/apply.service';
import { TrackingserviceService } from './services/trackingservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private trackingserviceService: TrackingserviceService,
    public router: Router,
    public applyService: ApplyService) {
  }

  ngOnInit() {
    this.trackingserviceService.saveInitialSource();
    this.trackingserviceService.storeUTMParameters();
    this.applyService.initialSource = this.trackingserviceService.getInitialSource();
    this.applyService.sessionTimestamp = this.applyService.getSessionTimestamp();
  }

}
