import { BehaviorSubject } from "rxjs";
import { applyReactForm } from "./applyReactForm";

const headerSection = {
    control: applyReactForm.controls.headerForm,
    submitted: false,
    changed: false,
     submitted$: new BehaviorSubject(false),options:
    [],
    title: null
};

const introductionSection = {
    control: applyReactForm.controls.introductionForm,
    submitted: false,
    changed: false,
    options: [
        // 'Herr', 'Frau'

    ],
    submitted$: new BehaviorSubject(false),
    title: " Schön, dass Du hier bist. Bitte stelle Dich kurz vor."
};

const ageSection = {
    control: applyReactForm.controls.ageForm,
    submitted: false,
    changed: false,
    options: [
        '18-25', '26-35', '36-45', '46+'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Wie alt bist Du?"
};

const professionSection = {
    control: applyReactForm.controls.professionForm,
    submitted: false,
    changed: false,
    options: [
        'Schüler / Schülerin', 'Student / Studentin', 'Angestellter / Angestellte', 'Selbständig', 'Arbeitsuchend', 'Sonstiges'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Dein aktueller Berufsstand"
};

const experienceSection = {
    control: applyReactForm.controls.experienceForm,
    submitted: false,
    changed: false,
    options: [
        'Ich habe noch nie eine Zeile Programm-Code geschrieben.',
        'Ich habe schon den ein oder anderen kleinen Kurs gemacht, aber ich habe dann irgendwann aufgehört weiterzumachen.',
        'Ich kann bereits die ein oder andere Programmiersprache ganz gut, bin aber noch lange kein Programmierer.',
        'Ich kenne mich mit Objektorientierter Programmierung aus und habe bereits meine eigene Websiten, Apps und Programme geschrieben.'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Welche der folgen Aussagen beschreibt Deine aktuelle Programmier-Erfahrung am besten?"
};

const desireSection = {
    control: applyReactForm.controls.desiresForm,
    submitted: false,
    changed: false,
    options: [
        'Webseiten','Apps', 'Spiele', 'Business Anwendungen', 'IT-Sicherheit'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Welche IT-Bereiche interessieren dich besonders?",
};

const stacksSection = {
    control: applyReactForm.controls.stacksForm,
    submitted: false,
    changed: false,
    options: [
        'Frontend', 'Backend', 'Fullstack'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Für welche Bereiche interessierst du dich?"
};

const willSection = {
    control: applyReactForm.controls.willForm,
    submitted: false,
    changed: false,
    options: [

    ],
     submitted$: new BehaviorSubject(false),
    title: "In einem Satz:\nWarum willst Du Dich gezielt online zum Programmierer / IT-Spezialist weiterbilden?"
};

const emailSection = {
    control: applyReactForm.controls.emailForm,
    submitted: false,
    changed: false,
    options: [

    ],
     submitted$: new BehaviorSubject(false),
    title: "Deine E-Mail für die Terminbestätigung"
};

const phoneNumberSection = {
    control: applyReactForm.controls.phoneNumberForm,
    submitted: false,
    changed: false,
    options: [

    ],
     submitted$: new BehaviorSubject(false),
    title: "Unter welcher Telefonnummer können wir Dich für das Beratungsgespräch erreichen?"
};

const reachableSection = {
    control: applyReactForm.controls.reachableForm,
    submitted: false,
    changed: false,
    options: [
        'Morgens, zwischen 8-12 Uhr',
        'Nachmittags, zwischen 13-17 Uhr',
        'Abends, zwischen 17-20 Uhr'
    ],
     submitted$: new BehaviorSubject(false),
    title: "Wann bist Du in der Regel am besten (telefonisch) erreichbar?"
};

const currentSituationSection = {
    control: applyReactForm.controls.currentSituationForm,
    submitted: false,
    changed: false,
    options: [
        'Ich habe die finanzielle Möglichkeit, um in meine Weiterbildung zu investieren.',
        'Ich habe im Notfall Zugang zu finanziellen Ressourcen, um in meine Weiterbildung zu investieren.',
        'Ich habe nicht die finanziellen Möglichkeiten, um in meine berufliche Zukunft zu investieren und habe dies auch nicht vor.'
    ],
    submitted$: new BehaviorSubject(false),
    title: "Beschreibe bitte Deine aktuelle Situation"
};

const acceptSection = {
    control: applyReactForm.controls.acceptForm,
    submitted: false,
    changed: false,
    options: [
        { value: true, text: 'Ja, ich stimme zu.' } // ' und möchte an der kostenlosen Beratung teilnehmen.'
        ,
        { value: false, text: 'Nein, ich stimme nicht zu.' }

    ],
    submitted$: new BehaviorSubject(false),
    title: "Datenschutzhinweis" // Klicke jetzt hier, um zuzustimmen: *
};

export const applyFormSections = [
    headerSection,
    introductionSection,
    ageSection,
    professionSection,
    experienceSection,
    desireSection,
    stacksSection,
    willSection,
    emailSection,
    phoneNumberSection,
    reachableSection,
    //currentSituationSection,
    // acceptSection,
];