import { ApplyConfig } from "src/app/apply/services/apply.service";

const applyConfig: ApplyConfig = {
  endPoint: './postTunnel.php',
};
export const environment = {
  production: true,
  envName: 'production',
  applyConfig
};
