import { Component, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-apply-btn-wrapper',
  templateUrl: './apply-btn-wrapper.component.html',
  styleUrls: ['./apply-btn-wrapper.component.scss']
})
export class ApplyBtnWrapperComponent implements OnInit {

  constructor() { }

  @Input() valid! : boolean;
  @Input() invalid! : boolean;
  @Input() onInvalidMessage! : string;
  @Input() btnDisabled! : boolean;
  @Input() onValidMessage! : string;
  @Input() showCheck : boolean = true;
  @Output() onSubmitSection = new EventEmitter();
  @Input() showTitle : boolean = false;
  @Input() btnIcon : string = 'check'

  ngOnInit(): void {
  }

  emitSubmitting(event: Event){
    const button = event.target as HTMLButtonElement;
    if(button)
      button.style.pointerEvents = 'none';
    this.onSubmitSection.emit(event);
  }

}
