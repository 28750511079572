<div class="btns-container">
    <div>
        <button [disabled]="disablePrevBtn" (click)="onPreviousBtn.emit()" class="apply-btn-default btn-active">
            <img src="assets/apply_form/btn-up.svg">
        </button>


        <button [disabled]="disabledNextBtn" (click)="onNextBtn.emit()"
            class="apply-btn-default btn-inactive">
            <img [style.display]="!disabledNextBtn ? 'none' : 'block'" src="assets/apply_form/btn-down.svg">
            <img [style.display]="disabledNextBtn ? 'none' : 'block'" src="assets/apply_form/btn-down-active.svg">
        </button>
    </div>
    <div class="progress-container">
        <p class="hide-mobile">{{ index }}/10</p>
        <p class="hide-desktop">{{ index }}</p>
        <svg class="hide-desktop" width="99" height="3" viewBox="0 0 99 3" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="99" height="3" fill="#E8ECEE"/>
            <rect [attr.width]="index / 10 * 99" height="3" fill="#FD5B4F"/>
        </svg>
        <p class="hide-desktop" [style.color]="index == 10 ? '#FD5B4F' : '#b6c4c9'">10</p>
    </div>
</div>