import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit {

  private focus = true;

  constructor(private el: ElementRef) {
  }
  ngAfterViewInit(): void {
    if (this.focus) {
      //Otherwise Angular throws error: Expression has changed after it was checked.
      window.setTimeout(() => {
        this.el.nativeElement.focus(); //For SSR (server side rendering) this is not safe. Use: https://github.com/angular/angular/issues/15008#issuecomment-285141070)
      }, 1300);
    }
  }

  @Input() set appAutoFocus(condition: boolean) {
    this.focus = condition !== false;
  }

}
