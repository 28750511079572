import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class ThankYouPageEventTrackingService {

  constructor() { }
  private nameSubject = new BehaviorSubject<string>('');
  private experienceSubject = new BehaviorSubject<string>('');

  setName(name: string) {
    this.nameSubject.next(name);
  }

  setExperience(name: string) {
    this.nameSubject.next(name);
  }

  getName() {
    return this.nameSubject.asObservable();
  }
  public trackEvent() {
    gtag('event', 'conversion', { 'send_to': 'AW-939903939/D6csCPnP6IsCEMOXl8AD' });
  }
}