import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-apply-form-nav',
  templateUrl: './apply-form-nav.component.html',
  styleUrls: ['./apply-form-nav.component.scss']
})
export class ApplyFormNavComponent implements OnInit {

  @Input() index !: number; 
  @Input() disablePrevBtn !: boolean;
  @Input() disabledNextBtn !: boolean;
  @Output() onNextBtn = new EventEmitter();
  @Output() onPreviousBtn = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }



}
