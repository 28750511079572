<div class="selected-container" (click)="openPopUp = !openPopUp">
    <img *ngFor="let countryCode of countryCodes" src="assets/img/flags/{{countryCode}}.png" alt="">
    <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4467 2.00024L9.67648 9.00024L1.90625 2.00024" stroke="#124658" stroke-width="2.66099"
            stroke-linecap="round" stroke-linejoin="round" />
    </svg>

</div>
<div *ngIf="openPopUp" class="select-country-container">
    <div style="display:flex">
        <button (click)="closePopUp()" class="apply-btn-default btn-active">
            <img style="transform: rotate(-90deg);" src="assets/apply_form/btn-up.svg">
        </button>
        <input [appAutoFocus]="true" #countrysearch style="margin-left: 20px; width: -webkit-fill-available;" (keyup)="sortCountries(countrysearch.value)" class="apply-input" placeholder="Land suchen">
    </div>
    <div class="all-countries">
        <div *ngFor="let country of countriesToDisplay" (click)="selectCountry(country)" style="margin-top: 8px;">
            <img width="40px"  src="assets/img/flags/{{country.code.toLowerCase()}}.png" alt="">
            <span>{{country.name}}</span>
        </div>
    </div>
</div>