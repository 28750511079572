import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { COUNTRY_CODES } from '../../countryCodes';

@Component({
  selector: 'app-apply-country-flag',
  templateUrl: './apply-country-flag.component.html',
  styleUrls: ['./apply-country-flag.component.scss']
})
export class ApplyCountryFlagComponent implements OnChanges {

  @Input() phoneNumber!: FormControl;
  @Output() countryCodeSelected = new EventEmitter<string>();
  @Output() countryCodeValid = new EventEmitter<boolean>();
  countryCodes: string[] = ['de'];
  readonly LARGEST_COUNTRY_CODES_LENGTH = 5; // ex.: +1264
  readonly SMALLEST_CONTRY_CODES_LENGTH = 2; // ex.: +1
  openPopUp = false;
  countriesToDisplay = COUNTRY_CODES;

  constructor(private el: ElementRef, private renderer: Renderer2){}

  ngOnInit(){
    this.handlePhoneInputChange(this.phoneNumber.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.listenToPhoneNumberChanges();
  }
  private listenToPhoneNumberChanges() {
    this.phoneNumber.valueChanges
      .subscribe(this.handlePhoneInputChange.bind(this));
  }

  private handlePhoneInputChange(phoneInput : string) {
    phoneInput = this.filterPhoneInput(phoneInput);
    this.countryCodes = this.getCountryCodes(phoneInput);
    if(this.countryCodes.length == 0){
      this.countryCodeValid.emit(false);
      this.renderer.setStyle(this.el.nativeElement, 'border-bottom-color', '#FD5B4F')
    } else{
      this.countryCodeValid.emit(true);
      this.renderer.setStyle(this.el.nativeElement, 'border-bottom-color', '#b6c4c9')
    }
    //this.countryCodes = this.countryCodes.length > 0 ? this.countryCodes : ['de'];
  }

  private filterPhoneInput(phoneInput: string) {
    phoneInput = phoneInput.trim().replace(/[\/\(\)\-\s]/g, '');
    if (phoneInput.startsWith('00'))
      phoneInput = phoneInput.replace('00', '+');
    if (phoneInput.length > this.LARGEST_COUNTRY_CODES_LENGTH)
      phoneInput = phoneInput.substring(0, this.LARGEST_COUNTRY_CODES_LENGTH);
    return phoneInput;
  }

  private getCountryCodes(phoneInput: string) {
    let codes = [] as string[];
    while (this.canFindCountryCodes(phoneInput)) {
      codes = this.filterCountryCodesToLowerCase(phoneInput);
      if (codes.length > 0) break;
      else phoneInput = this.stripDownLastDigit(phoneInput);
    }
    return codes;
  }

  private stripDownLastDigit(phoneInput: string): string {
    return phoneInput.substring(0, phoneInput.length - 1);
  }

  private canFindCountryCodes(phoneInput: string) {
    return phoneInput.length >= this.SMALLEST_CONTRY_CODES_LENGTH &&
      phoneInput.length <= this.LARGEST_COUNTRY_CODES_LENGTH &&
      phoneInput.startsWith('+');
  }

  private filterCountryCodesToLowerCase(phoneInput : string) {
    return COUNTRY_CODES
      .filter(p => p.dial_code == phoneInput)
      .map(found => found.code.toLowerCase());
  }

  /**
   * Filter Country Codes from smallest to largest
   */
  private filterCountryCodesByLength() {
    const oneDigitPrefixes = COUNTRY_CODES.filter(cc => cc.dial_code.length == 2);
    const twoDigitsPrefixes = COUNTRY_CODES.filter(cc => cc.dial_code.length == 3);
    const treeDigitsPrefixes = COUNTRY_CODES.filter(cc => cc.dial_code.length == 4);
    const fourDigitsPrefixes = COUNTRY_CODES.filter(cc => cc.dial_code.length == 5);
  }

  /**
   * Sort Country Codes from smallest to largest
   * and from a - z
   */
  private sortCountryCodes() {
    COUNTRY_CODES.sort((a, b) => a.dial_code.length - b.dial_code.length);
    COUNTRY_CODES.sort((a, b) => a.dial_code.localeCompare(b.dial_code));
  }

  closePopUp(){
    this.openPopUp = false;
    this.countriesToDisplay = COUNTRY_CODES;
  }

  selectCountry(country: {name : string, dial_code : string, code : string}){
    this.countryCodeSelected.emit(country.dial_code);
    this.closePopUp();
  }

  sortCountries(search: string){
    this.countriesToDisplay = [];
    COUNTRY_CODES.forEach((country) => {
      if(country.name.includes(search)){
        this.countriesToDisplay.push(country);
      }
    })
  }
}
