import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { TrackingserviceService } from '../services/trackingservice.service';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplyFormAnimations } from './apply.animations';
import { ApplyService } from './services/apply.service';
import { EMAIL_REGEX, NO_WHITE_SPACE_REGEX } from './applyReactForm';
import { ThankYouPageEventTrackingService } from "../services/thank-you-page-event-tracking.service"

@Component({
    selector: 'app-apply',
    templateUrl: './apply.component.html',
    styleUrls: ['./apply.component.scss'],
    animations: [ApplyFormAnimations]
})

export class ApplyComponent implements OnInit, AfterViewInit {

    firstSectionSubmitted: boolean = false;
    countryCodeValid: boolean = true;

    constructor(
        private trackingserviceService: TrackingserviceService,
        private router: Router,
        public applyService: ApplyService,
        private thankYouPageEventTrackingService: ThankYouPageEventTrackingService
    ) { }


    ngOnInit(): void {
        // this.initialSource = this.trackingserviceService.getInitialSource();
    }


    async ngAfterViewInit(): Promise<void> {
        // this.sessionTimestamp = this.getSessionTimestamp();
        // this.checkSession();
        this.focusFirstFormOnTimeout();
    }

    async nextSection(event: any | undefined = undefined) {
        event?.preventDefault();// prevent form from submitting
        this.firstSectionSubmitted = true;
        this.applyService.logger.log("NEXTSECTION::Current Section : ", this.applyService.sections[this.applyService.currentSection]);
        const toPostSection = this.applyService.currentSection;

        if (this.applyService.canNavigateToNextSection()) {
            this.applyService.currentSection++;
            // this.window.history.pushState({'page_id' : this.applyService.currentSection}, '');
        }

        this.focusFirstFormOnTimeout();

        /**
         * Temporally disabled
         */
        if (this.applyService.canPostSection(toPostSection))
            this.postSection(toPostSection);

        // if(this.applyService.canPostSession(toPostSection)){
        //   this.postSession(toPostSection);
        // }
    }

    async postSection(toPostSection: number, postSectionTry = 0) {
        postSectionTry++;
        this.applyService.triggerLoadingFeedback(toPostSection);

        try {
            const message = this.applyService.getPayload(toPostSection);
            this.checkNameAndWill(message);
            if (toPostSection == 8) {
                let newEmail = this.replaceEmailend(message.form.email);
                message.form.email = newEmail;
                this.applyService.applyForm.controls.emailForm.get('email')?.setValue(newEmail);
            }
            const response = await this.applyService.fetching.post(message);
            await this.handleSendResponse(response, toPostSection);
            this.applyService.markAsSubmitted(toPostSection);
        } catch (error: any) {
            this.handleSendError(error, toPostSection);
        }
    }


    checkNameAndWill(message: any) {
        let regEx = /^\s+|\s+$|\s+(?=\s)/g;
        if (message.form.firstName) {
            let regExFirstName = message.form.firstName.replace(regEx, '');
            this.thankYouPageEventTrackingService.setName(regExFirstName);
            message.form.firstName = regExFirstName;
            let regExLastName = message.form.lastName.replace(regEx, '');
            message.form.lastName = regExLastName;
        }
        if (message.form.will) {
            let regExWill = message.form.will.replace(regEx, '');
            message.form.will = regExWill;
        }
    }


    getSectionToWaitFor(toPostSection: number): number | false {
        if (
            toPostSection > this.applyService.SESSIONS_ROW_NECESSARY_SECTION &&
            toPostSection <= this.applyService.LEADS_ROW_NECESSARY_SECTION &&
            !this.applyService.sections[this.applyService.SESSIONS_ROW_NECESSARY_SECTION].submitted
        )
            return this.applyService.SESSIONS_ROW_NECESSARY_SECTION;
        if (
            toPostSection > this.applyService.LEADS_ROW_NECESSARY_SECTION &&
            !this.applyService.sections[this.applyService.LEADS_ROW_NECESSARY_SECTION].submitted
        )
            return this.applyService.LEADS_ROW_NECESSARY_SECTION;
        return false;
    }

    previousSectionSubmitted(toPostSection: number) {
        const previousSection = toPostSection - 1;
        if (previousSection == -1)
            return true;
        return this.applyService.sections[previousSection].submitted;
    }

    handleSendResponse(response: any, toPostSection: number) {
        this.applyService.stopLoadingFeedback(toPostSection);

        if (this.applyService.isLastSection(toPostSection)) { //last section posted
            //pass email to DOM Element - email GTM Variable
            try {
                localStorage.removeItem('sessionTimestamp');
                (document.getElementById("completeRegistrationEmail") as HTMLElement).innerText = this.applyService.applyForm.controls.emailForm.value.email;
                this.router.navigate(['bewerbung-eingegangen']);
            } catch (error) {
                (document.getElementById("completeRegistrationEmail") as HTMLElement).innerText = this.applyService.applyForm.controls.emailForm.value.email;
                this.router.navigate(['bewerbung-eingegangen']);
            }
        }
        return Promise.resolve();
    }

    /**
     * Handles error on send of section, navigating the user to not posted section
     * @param { any } error - error on posting to script App
     * @param { number } toPostSection - index of the Posted section
     */
    handleSendError(error: { code: string, type: string, message: string }, toPostSection: number) {
        this.applyService.sections[toPostSection].submitted = false;
        this.applyService.sections[toPostSection].submitted$.next(false);
        this.applyService.fetching.fetching = false;
        this.applyService.collectErrors(error);
        this.promptFeedback(toPostSection, error);
    }

    promptFeedback(toPostSection: number, error: { code: string, type: string, message: string }) {
        const errorMessage = this.getPostErrorMessage(error);
        const sanitizedresponse = this.applyService.sanitizer.bypassSecurityTrustHtml(`
                <span>${errorMessage}</span><br>
                <span>Versuchen Sie es bitte erneut.</span>
                <span>Sie können uns direkt unter folgender E-Mail-Adresse kontaktieren: </span>
                <a class="apply-btn-default menu" href="mailto:info@developerakademie.com">info@developerakademie.com</a>`
        );

        this.applyService.fetching.showResponseFeedback(
            sanitizedresponse
        );
        this.navigateToSection(toPostSection); // Navigate to last section that could not be posted
    }

    getPostErrorMessage(error: { code: string, type: string, message: string }) {
        try {
            if (error.message.includes('Registrierungslimit'))
                return error.message;
            else return 'Leider konnte deine letzte Eingabe nicht übermittelt werden.';
        } catch (error) {
            return 'Leider konnte deine letzte Eingabe nicht übermittelt werden.';
        }


    }

    /**
     * Show a section, giving ist position number from 0 to sections.length - 1
     * @param {number} pos A number between 0 - sections.length - 1
     */
    navigateToSection(pos: number) {
        if (pos < this.applyService.sections.length && pos >= 0) {
            this.applyService.currentSection = pos;
            this.focusFirstFormOnTimeout();
        }
    }

    previousSection() {
        if (this.applyService.currentSection > 0) {
            this.applyService.currentSection--;
            this.focusFirstFormOnTimeout();
        }
    }

    /**
    * Used to select / unselect Options via Keyup event
    */
    alphabet: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    /**
     * Handles keyup event on current section for options select
     * tabindex needs to be set and focused on current section container for keyup event to trigger
     */
    handleOptionKeyUp(event: KeyboardEvent, options: string[] | { value: boolean; text: string; }[], control: FormControl | FormControl[]) {
        const key = event.key.toLowerCase();
        const index = this.alphabet.indexOf(key);
        const upKeyInAlphabetAndInOptionsRange = index != -1 && index < options.length;

        if (upKeyInAlphabetAndInOptionsRange) {
            if (control instanceof Array)
                this.handleSelection(null, control[index], options[index])
            else if (this.applyService.currentSection < this.applyService.sections.length - 1)
                this.handleSelection(null, control, options[index])
            else
                this.handleSelection(null, control, options[index])
        }
    }

    handleLastDependedOptionKeyUp(event: KeyboardEvent, options: string[] | { value: boolean; text: string; }[], controls: FormControl[]) {
        const key = event.key.toLowerCase();
        const index = this.alphabet.indexOf(key);
        const upKeyInAlphabetAndInOptionsRange = index != -1 && index < options.length;

        if (upKeyInAlphabetAndInOptionsRange) {
            if (this.isLastControl(controls[index]))
                this.handleSelectionOfDependentOption(null, controls[index], options[index]);
            else
                this.handleSelectionWithLastOptionDependent(null, controls[index], options[index]);
        }
    }

    /**
     * Handles select / unselect of an option for the current section
     */
    handleSelection(event: PointerEvent | null, control: FormControl, selection: string | { value: boolean; text: string; } | boolean) {
        event?.preventDefault();
        control.markAsDirty();
        let newValue = control.value == selection ? null : selection;
        control.setValue(newValue);

        if (newValue != null) this.focusFirstForm();
    }

    handleSelectionOfDependentOption(event: PointerEvent | null, control: FormControl, selection: string | { value: boolean; text: string; }) {
        this.handleSelection(event, control, selection);
        if (control.value == null) {
            control.parent?.parent?.reset();
        } else {
            this.selectAllOptionsExceptLastOptionFromControlParent(control);
        }
    }

    selectAllOptionsExceptLastOptionFromControlParent(control: AbstractControl) {
        const allControls = control.parent?.controls as AbstractControl[];
        const allControlsExceptLast = allControls.slice(0, allControls.length - 1);
        allControlsExceptLast.forEach(c => {
            this.selectOptionFromControl(c as FormControl);
        });
    }

    selectOptionFromControl(c: FormControl) {
        const option = this.findOptionFromControl(c);
    }

    findOptionFromControl(control: FormControl) {
        const allControls = control.parent?.controls as AbstractControl[];
        const index = allControls.indexOf(control);
        const option = this.applyService.getSectionOptionsByControl((control.parent?.parent as AbstractControl))[index];
        control.setValue(option);
    }

    handleSelectionWithLastOptionDependent(event: PointerEvent | null, control: FormControl, selection: string | { value: boolean; text: string; }) {
        this.handleSelection(event, control, selection);
        if (this.allAreSelectedExeptLastOption(control)) {
            this.selectLastOptionFromControlParent(control);
        } else {
            this.unselectLastOptionFromControlParent(control);
        }
    }

    allAreSelectedExeptLastOption(control: FormControl) {
        const allControls = control.parent?.controls as AbstractControl[];
        const allControlsExceptLast = allControls.slice(0, allControls.length - 1);
        this.applyService.logger.log(allControlsExceptLast);
        return allControlsExceptLast.every(c => c.value != null);
    }

    selectLastOptionFromControlParent(control: FormControl) {
        const lastControl = this.getLastControlFromControlParent(control);
        const option = this.applyService.getSectionOptionsByControl((control.parent?.parent as AbstractControl))[this.applyService.getSectionOptionsLengthByControl((control.parent?.parent as AbstractControl)) - 1];
        lastControl.setValue(option);
    }

    unselectLastOptionFromControlParent(control: FormControl) {
        const lastControl = this.getLastControlFromControlParent(control);
        lastControl.setValue(null);
        this.applyService.logger.log(lastControl);
    }

    getLastControlFromControlParent(control: AbstractControl) {
        return (control.parent?.controls as AbstractControl[])[(control.parent?.controls as AbstractControl[]).length - 1];
    }

    isLastControl(control: FormControl) {
        return (control.parent?.controls as AbstractControl[]).slice(-1)[0] == control;
    }

    focusApplyBtn() {
        (document.querySelector('form button.apply-btn-default') as HTMLButtonElement).focus();
    }

    /**
     * Help function to get a map from an array as a field[]
     * @param options - array to map from
     * @param field - field to map to
     * @returns { field[] }
     */
    getMapOf(options: any[], field: string) {
        return options.map(o => o[field]);
    }

    focusFirstFormOnTimeout() {
        setTimeout(() => {
            this.focusFirstForm();
        }, 1000);
    }

    /**
     * TODO
     * Throws TypeError Cannot read properties of null (reading 'setAttribute')
     * Observations : Happened after leaving Router from /termin to /bewerbung-eingegangen and back navigated
     */
    focusFirstForm() {
        document.querySelector('form')?.setAttribute('tabindex', '-1');
        document.querySelector('form')?.focus();
    }

    // handleEnterOnOption(event){
    //   event.preventDefault();
    // }

    @HostListener('document:keyup.enter', ['$event'])
    onEnterUp(event: KeyboardEvent) {
        this.applyService.logger.log("Enter Key UP : ", event);
        this.applyService.logger.log("Active Element : ", document.activeElement);
        this.applyService.logger.log("target Element : ", event.target);

        if (document.activeElement instanceof HTMLFormElement)
            this.nextSection();
    }

    @HostListener('document:keydown.enter', ['$event'])
    onEnterDown(event: KeyboardEvent) {

    }

    @HostListener('document:keyup.tab', ['$event'])
    onTab(event: KeyboardEvent) {
        this.applyService.logger.log("TAB Key UP : ", event);
        this.applyService.logger.log("Active Element : ", document.activeElement);
        this.applyService.logger.log("target Element : ", event.target);
    }

    /**Value is written already in input field*/
    handleInputPhoneNumber(event: InputEvent) {
        let regexAllowedChars = /[\+\d\-\/\(\)]{1,1}/;
        let inputField = event.target as HTMLInputElement;
        let inputFieldValue = inputField.value;

        let inputFieldValueAsArray = Array.from(inputFieldValue);

        for (let index = 0; index < inputFieldValueAsArray.length; index++) {
            const character = inputFieldValueAsArray[index];
            if (!regexAllowedChars.test(character)) {
                inputFieldValueAsArray[index] = '';
            }
        }
        inputField.value = inputFieldValueAsArray.join('');
    }

    findIndexesOfNotAllowedChars(regexAllowedChars: RegExp, string: string) {
        let notAllowedCharIndexes = [];
        for (let index = 0; index < string.length; index++) {
            const charInInput = string[index];
            if (!regexAllowedChars.test(charInInput))
                notAllowedCharIndexes.push(index);
        }
        return notAllowedCharIndexes;
    }

    emailRegex = EMAIL_REGEX;
    noWhiteSpaceRegex = NO_WHITE_SPACE_REGEX;
    getErrorMessage(control: FormControl | AbstractControl) {
        //applyService.applyForm.controls.phoneNumberForm.get('phoneNumber').hasError('pattern') ? 'Bitte nur Zahlen eingeben' : 'Bitte ausfüllen'
        if (control.hasError('required'))
            return 'Bitte ausfüllen';
        if (control.hasError('minlength'))
            return 'Bitte mindestens ' + control.errors?.minlength.requiredLength + ' Zeichen eingeben. Erlaubt sind Zahlen, "+" am Anfang, "-", "/" und "()" für die Vorwahl.';
        if (control.hasError('pattern'))
            return 'Bitte Zahlen eingeben. Erlaubt sind "+" am Anfang, "-", "/" und "()" für die Vorwahl.';
    }

    handleBlurEvent(event: Event, control: FormControl | AbstractControl) {
        if (control.invalid) {
            (event.target as HTMLFormElement).focus();
        }
    }

    replaceEmailend(originalString: String) {
        let splitedString = originalString.split('.');
        if (splitedString[splitedString.length - 1] === 'con') {
            splitedString[splitedString.length - 1] = 'com';
        }
        return splitedString.join('.');
    }

    autoGrowText(event: any): void {
        event.target.style.height = 'auto'; // Setzt die Höhe auf 'auto' zurück
        event.target.style.height = event.target.scrollHeight + 'px'; // Setzt die Höhe auf die Scrollhöhe
    }

    onCountryCodeSelected(newCountryCode: string) {
        this.applyService.applyForm.controls.phoneNumberForm.get('phoneNumber')?.setValue(newCountryCode);
    }

    onCountryCodeValid(countryCodeValid: boolean) {
        this.countryCodeValid = countryCodeValid;
    }


    public triggerFormSubmit(): void {
        let submitBtn = document.getElementById('applyBtn') as HTMLButtonElement;
        if (submitBtn) {
            submitBtn.click();
        }
    }

}