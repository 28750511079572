<div class="wrapper" [@enterLeave]="applyService.currentSection">
    <img src="assets/apply_form/Logo.svg" alt="" class="logo-wrapper hide-mobile">


    <img *ngIf="applyService.currentSection != 0" src="assets/apply_form/Logo-dark-font.svg" alt="" class="logo-wrapper hide-desktop">


    <div class="side-content">

        <div class="image-container hide-mobile">
            <img srcset="assets/apply_form/03_Mentor@2x.webp 2x, assets/apply_form/03_Mentor@3x.webp 3x"
                src="assets/apply_form/03_Mentor@1x.webp" alt="">
        </div>
        <div class="trustpilot-widget da-trust-pilot" data-locale="de-DE" data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="5e9825574f392a0001029067" data-style-height="120px" data-style-width="100%"
            data-theme="light" data-text-color="#050505">
            <a id="da-trustpilot" href="https://de.trustpilot.com/review/developerakademie.com" target="_blank"
                rel="noopener">Trustpilot</a>
        </div>
        <img class="TUV-certificate" src="assets\img\TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png">
        <app-apply-form-nav *ngIf="applyService.currentSection != 0" [disablePrevBtn]="applyService.currentSection == 0"
            [disabledNextBtn]="(applyService.currentSection != 0 && applyService.sections[applyService.currentSection].control.invalid) ||
                    applyService.fetching.fetching ||
                    applyService.applyForm.disabled" [index]="applyService.currentSection" (onNextBtn)="triggerFormSubmit()"
            (onPreviousBtn)="previousSection()">
        </app-apply-form-nav>

    </div>
    <app-apply-form-nav class="hide-desktop" *ngIf="applyService.currentSection != 0" [disablePrevBtn]="applyService.currentSection == 0"
            [disabledNextBtn]="(applyService.currentSection != 0 && applyService.sections[applyService.currentSection].control.invalid) ||
                    applyService.fetching.fetching ||
                    applyService.applyForm.disabled" [index]="applyService.currentSection" (onNextBtn)="triggerFormSubmit()"
            (onPreviousBtn)="previousSection()">
    </app-apply-form-nav>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.headerForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.headerForm)" #headerForm
        [formGroup]="applyService.applyForm.controls.headerForm" class="form-part--first section">
        <img src="assets/apply_form/Logo-dark-font.svg" alt="" class="logo-wrapper hide-desktop">

        <mat-card class="wrapper-content wrapper-content--first">

            <div class="hide-desktop mobile-image-head">
                <img srcset="assets/apply_form/03_Mentor@2x.webp 2x, assets/apply_form/03_Mentor@3x.webp 3x"
                    src="assets/apply_form/03_Mentor@1x.webp" alt="">
            </div>

            <div class="wrapper-title--first">

                <div class="text-container">
                    <div>
                        <h1 class="head" style="color: #124658;">Starte deine&nbsp;<strong
                                style="color: #FD5B4F">IT-Laufbahn</strong></h1>
                    </div>
                    <p class="headline">Mit unserem kostenlosen 45-minütigen Beratungsgespräch:</p>

                    <div class="steps">
                        <div class="step">
                            <img src="assets/apply_form/eignungscheck.svg" alt="">
                            <span class="menu"><strong>Eignungs-Check: </strong>Ist eine Ausbildung zum IT-Spezialisten
                                dein Ding?</span>
                        </div>
                        <div class="step">
                            <img src="assets/apply_form/karriereplan.svg" alt="">
                            <span class="menu"><strong>Karriereplan: </strong>Bekomme deinen maßgeschneiderten Plan zum
                                ersten IT-Job</span>
                        </div>
                    </div>
                </div>

            </div>

            <div class="wrapper-accept">

                <div class="example-margin apply-option-container first-section-option-container" (click)="handleSelection($event, applyService.applyForm.controls.headerForm?.get('accept'), !applyService.applyForm.controls.headerForm?.get('accept')['value'])">
                    <button
                            [class.apply-option--selected]="applyService.applyForm.controls.headerForm?.get('accept')['value']"
                            class="apply-option" [disabled]="applyService.applyForm.disabled" style="border-radius: 10px; margin-left: 0;">
                            <mat-icon *ngIf="applyService.applyForm.controls.headerForm?.get('accept')['value']"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>
                        </button>
                    <div class="checkbox-title">
                        <span class="menu" style="color: #627B84;">Bitte stimme kurz der <a class="data-protection-link" rel="noopener noreferrer" target="_blank"
                            (click)="$event.stopPropagation();" href="https://developerakademie.com/data-protection">Datenschutzerklärung</a> zu.</span>
                        <p *ngIf="applyService.applyForm.controls.headerForm.invalid && firstSectionSubmitted"
                            class="invalid-dataprot">Bitte akzeptiere die Datenschutzerklärung.</p>
                    </div>
                </div>
                <div *ngIf="!(applyService.applyForm.controls.headerForm.invalid && firstSectionSubmitted)"
                    class="placeholder-checked-dataprot"></div>


                <!-- <input type="checkbox" id="accept">
                <label class="title-3" for="accept"></label> -->
            </div>
            <div class="first-section-footer">
                <p>Es folgen ein paar Fragen für unser Gespräch</p>
                <img style="padding-bottom: 22px" src="assets/apply_form/Arrow.svg">
                <div>
                    <button [ngClass]="{'da-btn-fake-disabled': applyService.applyForm.controls.headerForm.invalid}"
                        class="da-btn">Starten</button>
                    <span style="font-weight: 500;"><img src="assets/apply_form/schedule.svg">3 Minuten!</span>
                </div>
            </div>

        </mat-card>
        <div class="bottom-content">
            <div class="trustpilot-widget da-trust-pilot" data-locale="de-DE"
                data-template-id="53aa8807dec7e10d38f59f32" data-businessunit-id="5e9825574f392a0001029067"
                data-style-height="120px" data-style-width="100%" data-theme="light" data-text-color="#050505">
                <a id="da-trustpilot" href="https://de.trustpilot.com/review/developerakademie.com" target="_blank"
                    rel="noopener">Trustpilot</a>
            </div>
            <img class="TUV-certificate" src="assets\img\TR-Testmark_9000012503_DE_CMYK_without-QR-Code.png">

        </div>
        <app-footer ></app-footer>
    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.introductionForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.introductionForm)" #introductionForm
        [formGroup]="applyService.applyForm.controls.introductionForm" class="form-part section">
        <mat-card class="wrapper-content">
            <p class="custom-title"><strong style="color: #FD7065">Schön, dass Du hier bist.</strong>&nbsp;<strong style="color: #124658;">Bitte stelle dich kurz vor.</strong></p>

            <!-- <div class="form-fields">

                    <div class="form-field-container">
                        <label class="" for="gender">Anrede *</label>
                        <select class="" formControlName="gender">
                            <option [ngValue]="null" selected disabled></option>
                            <option *ngFor="let gen of allGenders" [ngValue]="gen">
                                {{gen}}</option>
                        </select>
                    </div>

                </div> -->

            <div class="form-fields">

                <div class="form-field-container">
                    <!-- <label class="" for="firstName">Vorname *</label> -->
                    <input [appAutoFocus]="true" [maxlength]="99" class="apply-input" type="text"
                        placeholder="Dein Vorname" formControlName="firstName" [pattern]="noWhiteSpaceRegex"
                        autocomplete="given-name" required>
                </div>

                <div class="form-field-container">
                    <!-- <label class="" for="lastName">Nachname *</label> -->
                    <input [maxlength]="99" class="apply-input" type="text" formControlName="lastName"
                        placeholder="Dein Nachname" [pattern]="noWhiteSpaceRegex" autocomplete="family-name" required>
                </div>

            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.introductionForm.invalid && ( applyService.applyForm.controls.introductionForm.get('firstName')?.dirty && applyService.applyForm.controls.introductionForm.get('lastName')?.dirty)"
                [valid]="applyService.applyForm.controls.introductionForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.introductionForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>
    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.ageForm)"
        (ngSubmit)="nextSection($event)" *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.ageForm)"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.ageForm), applyService.applyForm.get('ageForm')?.get('age'))"
        #ageForm [formGroup]="applyService.applyForm.controls.ageForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.ageForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container">

                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.ageForm); let i = index"
                        (click)="handleSelection($event, applyService.applyForm.controls.ageForm?.get('age'),option)">
                        <button
                            [class.apply-option--selected]="applyService.applyForm.controls.ageForm?.get('age')['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon *ngIf="applyService.applyForm.controls.ageForm?.get('age')['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>
                        </button>
                        <span class="menu">{{option}}</span>
                    </div>

                </div>

            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.ageForm.invalid && applyService.applyForm.controls.ageForm.get('age')?.dirty"
                [valid]="applyService.applyForm.controls.ageForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.ageForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>
    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.professionForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.professionForm)"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.professionForm), applyService.applyForm.controls.professionForm?.get('profession'))"
        #professionForm [formGroup]="applyService.applyForm.controls.professionForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.professionForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container">

                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.professionForm); let  i = index"
                        (click)="handleSelection($event, applyService.applyForm.controls.professionForm?.get('profession'),option)">
                        <button
                            [class.apply-option--selected]="applyService.applyForm.controls.professionForm?.get('profession')['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon
                                *ngIf="applyService.applyForm.controls.professionForm?.get('profession')['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>
                        </button>
                        <span class="menu">{{option}}</span>
                    </div>
                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.professionForm.invalid && applyService.applyForm.controls.professionForm.get('profession')?.dirty"
                [valid]="applyService.applyForm.controls.professionForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.professionForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.experienceForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.experienceForm)"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.experienceForm), applyService.applyForm.controls.experienceForm?.get('experience'))"
        #experienceForm [formGroup]="applyService.applyForm.controls.experienceForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.experienceForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container">

                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.experienceForm); let i = index"
                        (click)="handleSelection($event, applyService.applyForm.controls.experienceForm?.get('experience'),option)">
                        <button
                            [class.apply-option--selected]="applyService.applyForm.controls.experienceForm?.get('experience')['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon
                                *ngIf="applyService.applyForm.controls.experienceForm?.get('experience')['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>

                        </button>
                        <span class="menu menulong">{{option}}</span>
                    </div>
                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.experienceForm.invalid && applyService.applyForm.controls.experienceForm.get('experience')?.dirty"
                [valid]="applyService.applyForm.controls.experienceForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.experienceForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.desiresForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.desiresForm)"
        [formGroup]="applyService.applyForm.controls.desiresForm" #desiresForm class="form-part section"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.desiresForm), applyService.applyForm.get('desiresForm')?.get('desires').controls)">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.desiresForm)">
            </app-apply-form-title>

            <div class="form-fields">
                <div class="form-field-container">

                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.desiresForm); let i = index"
                        (click)="handleSelection($event, applyService.applyForm.controls.desiresForm.get('desires')?.controls[i],option)">
                        <button style="border-radius: 10px;"
                            [class.apply-option--selected]="applyService.applyForm.controls.desiresForm.get('desires')?.controls[i]['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon
                                *ngIf="applyService.applyForm.controls.desiresForm.get('desires')?.controls[i]['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>
                        </button>
                        <span class="menu">{{option}}</span>
                    </div>

                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.desiresForm.invalid && applyService.applyForm.controls.desiresForm.get('desires')?.dirty"
                [valid]="applyService.applyForm.controls.desiresForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.desiresForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.stacksForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.stacksForm)"
        (keyup)="handleLastDependedOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.stacksForm), applyService.applyForm.controls.stacksForm?.get('stacks').controls)"
        #stacksForm [formGroup]="applyService.applyForm.controls.stacksForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.stacksForm)">
            </app-apply-form-title>

            <div class="form-fields">
                <div class="form-field-container">
                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsRangeByControl(applyService.applyForm.controls.stacksForm, 0, -1); let i = index"
                        (click)="handleSelectionWithLastOptionDependent($event, applyService.applyForm.controls.stacksForm.get('stacks')?.controls[i],option)">
                        <button style="border-radius: 10px;"
                            [class.apply-option--selected]="applyService.applyForm.controls.stacksForm.get('stacks')?.controls[i]['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon
                                *ngIf="applyService.applyForm.controls.stacksForm.get('stacks')?.controls[i]['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>
                        </button>
                        <span class="menu">{{option}}</span>
                    </div>
                    <hr style="border: 1px solid black; width: 100%; background-color: black;">

                    <!-- Last Option -->
                    <div class="apply-option-container" (click)="handleSelectionOfDependentOption($event, applyService.applyForm.controls.stacksForm.get('stacks')?.controls[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                    - 1],applyService.getSectionOptionsByControl(applyService.applyForm.controls.stacksForm)[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                    - 1])">
                        <button type="button" style="border-radius: 10px;" [class.apply-option--selected]="applyService.applyForm.controls.stacksForm.get('stacks')?.controls[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                        - 1].value == applyService.getSectionOptionsByControl(applyService.applyForm.controls.stacksForm)[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                        - 1]" class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon *ngIf="applyService.applyForm.controls.stacksForm.get('stacks')?.controls[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                            - 1].value == applyService.getSectionOptionsByControl(applyService.applyForm.controls.stacksForm)[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)
                            - 1]" aria-hidden="false" aria-label="Example arrow icon">
                                check
                            </mat-icon>

                        </button>
                        <span class="menu">{{applyService.getSectionOptionsByControl(applyService.applyForm.controls.stacksForm)[applyService.getSectionOptionsLengthByControl(applyService.applyForm.controls.stacksForm)-
                            1]}}</span>
                    </div>

                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.stacksForm.invalid && applyService.applyForm.controls.stacksForm.get('stacks')?.dirty"
                [valid]="applyService.applyForm.controls.stacksForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.stacksForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.willForm)"
        (ngSubmit)="nextSection($event)" *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.willForm)"
        #willForm [formGroup]="applyService.applyForm.controls.willForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.willForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container">
                    <textarea [appAutoFocus]="true" [maxLength]="249"
                        (blur)="handleBlurEvent($event, applyService.applyForm.controls.willForm?.get('will'))"  (input)="autoGrowText($event)"
                        class="apply-textarea" formControlName="will" id="" cols="30" rows="1" autocomplete="off"
                        placeholder="Gib hier deine Antwort ein..." required></textarea>
                </div>

            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.willForm.invalid && applyService.applyForm.controls.willForm.get('will')?.dirty"
                [valid]="applyService.applyForm.controls.willForm.valid" [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.willForm.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.emailForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.emailForm)" #emailForm
        [formGroup]="applyService.applyForm.controls.emailForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.emailForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container">
                    <input [appAutoFocus]="true" [maxlength]="99"
                        (blur)="handleBlurEvent($event, applyService.applyForm.controls.emailForm?.get('email'))"
                        class="apply-input" type="email" formControlName="email" placeholder="Deine E-Mail Adresse"
                        autocomplete="email" required>
                </div>

            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.emailForm.get('email')?.hasError('pattern') && applyService.applyForm.controls.emailForm.get('email')?.touched"
                [valid]="applyService.applyForm.controls.emailForm.valid"
                [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.emailForm.get('email')?.hasError('required') || applyService.applyForm.controls.emailForm.get('email')?.hasError('pattern')"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.phoneNumberForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.phoneNumberForm)" #phoneNumberForm
        [formGroup]="applyService.applyForm.controls.phoneNumberForm" class="form-part section">
        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.phoneNumberForm)">
            </app-apply-form-title>

            <div class="form-fields">

                <div class="form-field-container form-field-container__phone">
                    <app-apply-country-flag
                        [phoneNumber]="applyService.applyForm.controls.phoneNumberForm.get('phoneNumber')" (countryCodeValid)="onCountryCodeValid($event)" (countryCodeSelected)="onCountryCodeSelected($event)"></app-apply-country-flag>
                    <input [appAutoFocus]="true" inputmode="numeric"
                        (input)="handleInputPhoneNumber($event)" class="apply-input" type="text"
                        pattern="^\+?([\s\-\/]?\(?[\d]{1,16}\)?){1,5}\s?$" formControlName="phoneNumber" minlength="5"
                        placeholder="+12 345 67890123" autocomplete="tel" required [maxLength]="99">
                </div>
                <p *ngIf="!countryCodeValid" style="font-size: 12px; color: #FD5B4F; min-height: 32px;">Ungültige Landesvorwahl</p>
                <p *ngIf="countryCodeValid" style="font-size: 12px; color: #124658; min-height: 32px;">{{ getErrorMessage(applyService.applyForm.controls.phoneNumberForm?.get('phoneNumber')) }}</p>

            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.phoneNumberForm.get('phoneNumber')?.invalid && applyService.applyForm.controls.phoneNumberForm.get('phoneNumber')?.touched"
                [valid]="applyService.applyForm.controls.phoneNumberForm.valid"
                [onInvalidMessage]="'Weiter'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.phoneNumberForm.get('phoneNumber')?.invalid"
                [onValidMessage]="'Weiter'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <form [id]="'section'+applyService.getSectionIndexByControl(applyService.applyForm.controls.reachableForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.reachableForm)"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.reachableForm), applyService.applyForm.controls.reachableForm?.get('reachable'))"
        #reachableForm [formGroup]="applyService.applyForm.controls.reachableForm" class="form-part section">

        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.reachableForm)">
            </app-apply-form-title>

            <div class="form-fields">
                <div class="form-field-container">

                    <div class="apply-option-container"
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.reachableForm); let i = index"
                        (click)="handleSelection($event, applyService.applyForm.controls.reachableForm?.get('reachable'),option)">
                        <button
                            [class.apply-option--selected]="applyService.applyForm.controls.reachableForm?.get('reachable')['value'] == option"
                            class="apply-option" [disabled]="applyService.applyForm.disabled">
                            <mat-icon
                                *ngIf="applyService.applyForm.controls.reachableForm?.get('reachable')['value'] == option"
                                aria-hidden="false" aria-label="Example arrow icon">check</mat-icon>
                        </button>
                        <span class="menu">{{option}}</span>
                    </div>
                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.reachableForm.invalid && applyService.applyForm.controls.reachableForm.get('reachable')?.dirty"
                [valid]="applyService.applyForm.controls.reachableForm.valid" [onInvalidMessage]="'Übermitteln'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.reachableForm.invalid"
                [onValidMessage]="'Übermitteln'">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>

    <!--<form [id]="applyService.getSectionIndexByControl(applyService.applyForm.controls.currentSituationForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.currentSituationForm)"
        (keyup)="handleOptionKeyUp($event, applyService.getSectionOptionsByControl(applyService.applyForm.controls.currentSituationForm), applyService.applyForm.controls.currentSituationForm?.get('situation'))"
        #currentSituationForm [formGroup]="applyService.applyForm.controls.currentSituationForm"
        class="form-part section">
        <mat-card class="wrapper-content">
            <app-apply-form-title
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.currentSituationForm)">
            </app-apply-form-title>

            <div class="form-fields">
                <div class="form-field-container">

                    <div class="apply-option-container"
                    *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.currentSituationForm); let i = index"
                    (click)="handleSelection($event, applyService.applyForm.controls.currentSituationForm?.get('situation'),option)">
                    <button
                        [class.apply-option--selected]="applyService.applyForm.controls.currentSituationForm?.get('situation')['value'] == option"
                        class="apply-option" [disabled]="applyService.applyForm.disabled">
                        <mat-icon
                            *ngIf="applyService.applyForm.controls.currentSituationForm?.get('situation')['value'] == option"
                            aria-hidden="false" aria-label="Example arrow icon">check</mat-icon>
                    </button>
                    <span class="menu">{{option}}</span>
                </div>
                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="applyService.applyForm.controls.currentSituationForm.invalid && applyService.applyForm.controls.currentSituationForm.get('situation')?.dirty"
                [valid]="applyService.applyForm.controls.currentSituationForm.valid"
                [onInvalidMessage]="'Übermitteln'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || applyService.applyForm.controls.currentSituationForm.invalid"
                [onValidMessage]="'Übermitteln'" [showCheck]="false">
            </app-apply-btn-wrapper>

        </mat-card>

    </form>-->

    <!-- <form [id]="applyService.getSectionIndexByControl(applyService.applyForm.controls.acceptForm)"
        (ngSubmit)="nextSection($event)"
        *ngIf="applyService.isCurrentSection(applyService.applyForm.controls.acceptForm)"
        (keyup)="handleOptionKeyUp($event, getMapOf(applyService.getSectionOptionsByControl(applyService.applyForm.controls.acceptForm), 'value'), applyService.applyForm.controls.acceptForm.get('accept'))"
        #acceptForm [formGroup]="applyService.applyForm.controls.acceptForm" class="form-part section">

        <mat-card class="wrapper-content">

            <app-apply-form-header></app-apply-form-header>
            <app-apply-form-title
                [index]="applyService.getSectionIndexByControl(applyService.applyForm.controls.acceptForm)"
                [title]="applyService.getSectionTitleByControl(applyService.applyForm.controls.acceptForm)">
            </app-apply-form-title>

            <div>


                <p class="menu">Bitte stimme noch kurz unserer
                    <a target="blank" href="https://developerakademie.com/data-protection">Datenschutzerklärung</a> zu.
                </p>
            </div>

            <div class="form-fields">
                <div class="form-field-container">

                    <button
                        *ngFor="let option of applyService.getSectionOptionsByControl(applyService.applyForm.controls.acceptForm); let i = index"
                        [class.apply-option--selected]="applyService.applyForm.controls.acceptForm.get('accept')['value'] == option['value']"
                        class="apply-option menu" [disabled]="applyService.applyForm.disabled"
                        (click)="handleSelection($event, applyService.applyForm.controls.acceptForm.get('accept'),option['value'])">

                        {{alphabet[i] | titlecase}}. {{option['text']}}

                        <mat-icon
                            *ngIf="applyService.applyForm.controls.acceptForm.get('accept')['value'] == option['value']"
                            aria-hidden="false" aria-label="Example arrow icon">check</mat-icon>
                    </button>

                </div>
            </div>

            <app-apply-btn-wrapper
                [invalid]="(applyService.applyForm.invalid || applyService.applyForm.controls.acceptForm.invalid) && applyService.applyForm.controls.acceptForm.get('accept').dirty"
                [valid]="applyService.applyForm.controls.acceptForm.valid || applyService.applyForm.valid"
                [onInvalidMessage]="applyService.applyForm.controls.acceptForm.invalid ? 'Bitte zustimmen' : 'Bitte das Formular vollständig ausfüllen'"
                [btnDisabled]="applyService.applyForm.disabled || applyService.fetching.fetching || (applyService.applyForm.controls.acceptForm.invalid && applyService.applyForm.controls.acceptForm.get('accept').pristine)"
                [onValidMessage]="'Übermitteln'" [showCheck]="false">
            </app-apply-btn-wrapper>

        </mat-card>

    </form> -->

</div>

<div *ngIf="applyService.fetching.fetching" class="fetching progress">
    <div class="loading"></div>
</div>

<div *ngIf="applyService.fetching.response.error" class="response">
    <p [innerHTML]="applyService.fetching.response.message" class="message menu"></p>
    <button (click)="applyService.fetching.closeResponseFeedback()" class="apply-btn-default menu">Schließen</button>
</div>