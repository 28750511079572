export const COUNTRY_CODES = [
    
    {
        "name": "Deutschland",
        "dial_code": "+49",
        "code": "DE"
    },
    {
        "name": "Österreich",
        "dial_code": "+43",
        "code": "AT"
    },
    {
        "name": "Schweiz",
        "dial_code": "+41",
        "code": "CH"
    },
    {
        "name": "Afghanistan",
        "dial_code": "+93",
        "code": "AF"
    },
    {
        "name": "Alandinseln",
        "dial_code": "+358",
        "code": "AX"
    },
    {
        "name": "Albanien",
        "dial_code": "+355",
        "code": "AL"
    },
    {
        "name": "Algerien",
        "dial_code": "+213",
        "code": "DZ"
    },
    {
        "name": "Amerikanisch-Samoa",
        "dial_code": "+1684",
        "code": "AS"
    },
    {
        "name": "Andorra",
        "dial_code": "+376",
        "code": "AD"
    },
    {
        "name": "Angola",
        "dial_code": "+244",
        "code": "AO"
    },
    {
        "name": "Anguilla",
        "dial_code": "+1264",
        "code": "AI"
    },
    {
        "name": "Antarktis",
        "dial_code": "+672",
        "code": "AQ"
    },
    {
        "name": "Antigua und Barbuda",
        "dial_code": "+1268",
        "code": "AG"
    },
    {
        "name": "Argentinien",
        "dial_code": "+54",
        "code": "AR"
    },
    {
        "name": "Armenien",
        "dial_code": "+374",
        "code": "AM"
    },
    {
        "name": "Aruba",
        "dial_code": "+297",
        "code": "AW"
    },
    {
        "name": "Australien",
        "dial_code": "+61",
        "code": "AU"
    },
    {
        "name": "Aserbaidschan",
        "dial_code": "+994",
        "code": "AZ"
    },
    {
        "name": "Bahamas",
        "dial_code": "+1242",
        "code": "BS"
    },
    {
        "name": "Bahrain",
        "dial_code": "+973",
        "code": "BH"
    },
    {
        "name": "Bangladesch",
        "dial_code": "+880",
        "code": "BD"
    },
    {
        "name": "Barbados",
        "dial_code": "+1246",
        "code": "BB"
    },
    {
        "name": "Belarus",
        "dial_code": "+375",
        "code": "BY"
    },
    {
        "name": "Belgien",
        "dial_code": "+32",
        "code": "BE"
    },
    {
        "name": "Belize",
        "dial_code": "+501",
        "code": "BZ"
    },
    {
        "name": "Benin",
        "dial_code": "+229",
        "code": "BJ"
    },
    {
        "name": "Bermuda",
        "dial_code": "+1441",
        "code": "BM"
    },
    {
        "name": "Bhutan",
        "dial_code": "+975",
        "code": "BT"
    },
    {
        "name": "Bolivien",
        "dial_code": "+591",
        "code": "BO"
    },
    {
        "name": "Bosnien und Herzegowina",
        "dial_code": "+387",
        "code": "BA"
    },
    {
        "name": "Botsuana",
        "dial_code": "+267",
        "code": "BW"
    },
    {
        "name": "Brasilien",
        "dial_code": "+55",
        "code": "BR"
    },
    {
        "name": "Britisches Territorium im Indischen Ozean",
        "dial_code": "+246",
        "code": "IO"
    },
    {
        "name": "Brunei Darussalam",
        "dial_code": "+673",
        "code": "BN"
    },
    {
        "name": "Bulgarien",
        "dial_code": "+359",
        "code": "BG"
    },
    {
        "name": "Burkina Faso",
        "dial_code": "+226",
        "code": "BF"
    },
    {
        "name": "Burundi",
        "dial_code": "+257",
        "code": "BI"
    },
    {
        "name": "Kambodscha",
        "dial_code": "+855",
        "code": "KH"
    },
    {
        "name": "Kamerun",
        "dial_code": "+237",
        "code": "CM"
    },
    {
        "name": "Kanada",
        "dial_code": "+1",
        "code": "CA"
    },
    {
        "name": "Kap Verde",
        "dial_code": "+238",
        "code": "CV"
    },
    {
        "name": "Kaimaninseln",
        "dial_code": "+345",
        "code": "KY"
    },
    {
        "name": "Zentralafrikanische Republik",
        "dial_code": "+236",
        "code": "CF"
    },
    {
        "name": "Tschad",
        "dial_code": "+235",
        "code": "TD"
    },
    {
        "name": "Chile",
        "dial_code": "+56",
        "code": "CL"
    },
    {
        "name": "China",
        "dial_code": "+86",
        "code": "CN"
    },
    {
        "name": "Weihnachtsinsel",
        "dial_code": "+61",
        "code": "CX"
    },
    {
        "name": "Kokosinsel (Keeling)",
        "dial_code": "+61",
        "code": "CC"
    },
    {
        "name": "Kolumbien",
        "dial_code": "+57",
        "code": "CO"
    },
    {
        "name": "Komoren",
        "dial_code": "+269",
        "code": "KM"
    },
    {
        "name": "Kongo",
        "dial_code": "+242",
        "code": "CG"
    },
    {
        "name": "Demokratische Republik Kongo",
        "dial_code": "+243",
        "code": "CD"
    },
    {
        "name": "Cookinseln",
        "dial_code": "+682",
        "code": "CK"
    },
    {
        "name": "Costa Rica",
        "dial_code": "+506",
        "code": "CR"
    },
    {
        "name": "Elfenbeinküste",
        "dial_code": "+225",
        "code": "CI"
    },
    {
        "name": "Kroatien",
        "dial_code": "+385",
        "code": "HR"
    },
    {
        "name": "Kuba",
        "dial_code": "+53",
        "code": "CU"
    },
    {
        "name": "Tschechien",
        "dial_code": "+420",
        "code": "CZ"
    },
    {
        "name": "Dänemark",
        "dial_code": "+45",
        "code": "DK"
    },
    {
        "name": "Dschibuti",
        "dial_code": "+253",
        "code": "DJ"
    },
    {
        "name": "Dominica",
        "dial_code": "+1767",
        "code": "DM"
    },
    {
        "name": "Dominikanische Republik",
        "dial_code": "+1849",
        "code": "DO"
    },
    {
        "name": "Ecuador",
        "dial_code": "+593",
        "code": "EC"
    },
    {
        "name": "Ägypten",
        "dial_code": "+20",
        "code": "EG"
    },
    {
        "name": "El Salvador",
        "dial_code": "+503",
        "code": "SV"
    },
    {
        "name": "Äquatorialguinea",
        "dial_code": "+240",
        "code": "GQ"
    },
    {
        "name": "Eritrea",
        "dial_code": "+291",
        "code": "ER"
    },
    {
        "name": "Estland",
        "dial_code": "+372",
        "code": "EE"
    },
    {
        "name": "Äthiopien",
        "dial_code": "+251",
        "code": "ET"
    },
    {
        "name": "Falklandinseln (Malvinen)",
        "dial_code": "+500",
        "code": "FK"
    },
    {
        "name": "Färöer",
        "dial_code": "+298",
        "code": "FO"
    },
    {
        "name": "Fidschi",
        "dial_code": "+679",
        "code": "FJ"
    },
    {
        "name": "Finnland",
        "dial_code": "+358",
        "code": "FI"
    },
    {
        "name": "Frankreich",
        "dial_code": "+33",
        "code": "FR"
    },
    {
        "name": "French Guiana",
        "dial_code": "+594",
        "code": "GF"
    },
    {
        "name": "Französisch-Guyana",
        "dial_code": "+689",
        "code": "PF"
    },
    {
        "name": "Gabun",
        "dial_code": "+241",
        "code": "GA"
    },
    {
        "name": "Gambia",
        "dial_code": "+220",
        "code": "GM"
    },
    {
        "name": "Georgien",
        "dial_code": "+995",
        "code": "GE"
    },
    {
        "name": "Ghana",
        "dial_code": "+233",
        "code": "GH"
    },
    {
        "name": "Gibraltar",
        "dial_code": "+350",
        "code": "GI"
    },
    {
        "name": "Griechenland",
        "dial_code": "+30",
        "code": "GR"
    },
    {
        "name": "Grönland",
        "dial_code": "+299",
        "code": "GL"
    },
    {
        "name": "Grenada",
        "dial_code": "+1473",
        "code": "GD"
    },
    {
        "name": "Guadeloupe",
        "dial_code": "+590",
        "code": "GP"
    },
    {
        "name": "Guam",
        "dial_code": "+1671",
        "code": "GU"
    },
    {
        "name": "Guatemala",
        "dial_code": "+502",
        "code": "GT"
    },
    {
        "name": "Guernsey",
        "dial_code": "+44",
        "code": "GG"
    },
    {
        "name": "Guinea",
        "dial_code": "+224",
        "code": "GN"
    },
    {
        "name": "Guinea-Bissau",
        "dial_code": "+245",
        "code": "GW"
    },
    {
        "name": "Guyana",
        "dial_code": "+595",
        "code": "GY"
    },
    {
        "name": "Haiti",
        "dial_code": "+509",
        "code": "HT"
    },
    {
        "name": "Vatikanstadt",
        "dial_code": "+379",
        "code": "VA"
    },
    {
        "name": "Honduras",
        "dial_code": "+504",
        "code": "HN"
    },
    {
        "name": "Hongkong",
        "dial_code": "+852",
        "code": "HK"
    },
    {
        "name": "Ungarn",
        "dial_code": "+36",
        "code": "HU"
    },
    {
        "name": "Island",
        "dial_code": "+354",
        "code": "IS"
    },
    {
        "name": "Indien",
        "dial_code": "+91",
        "code": "IN"
    },
    {
        "name": "Indonesien",
        "dial_code": "+62",
        "code": "ID"
    },
    {
        "name": "Iran",
        "dial_code": "+98",
        "code": "IR"
    },
    {
        "name": "Irak",
        "dial_code": "+964",
        "code": "IQ"
    },
    {
        "name": "Irland",
        "dial_code": "+353",
        "code": "IE"
    },
    {
        "name": "Isle of Man",
        "dial_code": "+44",
        "code": "IM"
    },
    {
        "name": "Israel",
        "dial_code": "+972",
        "code": "IL"
    },
    {
        "name": "Italien",
        "dial_code": "+39",
        "code": "IT"
    },
    {
        "name": "Jamaika",
        "dial_code": "+1876",
        "code": "JM"
    },
    {
        "name": "Japan",
        "dial_code": "+81",
        "code": "JP"
    },
    {
        "name": "Jersey",
        "dial_code": "+44",
        "code": "JE"
    },
    {
        "name": "Jordanien",
        "dial_code": "+962",
        "code": "JO"
    },
    {
        "name": "Kasachstan",
        "dial_code": "+77",
        "code": "KZ"
    },
    {
        "name": "Kenia",
        "dial_code": "+254",
        "code": "KE"
    },
    {
        "name": "Kiribati",
        "dial_code": "+686",
        "code": "KI"
    },
    {
        "name": "Nordkorea",
        "dial_code": "+850",
        "code": "KP"
    },
    {
        "name": "Südkorea",
        "dial_code": "+82",
        "code": "KR"
    },
    {
        "name": "Kuwait",
        "dial_code": "+965",
        "code": "KW"
    },
    {
        "name": "Kirgisistan",
        "dial_code": "+996",
        "code": "KG"
    },
    {
        "name": "Laos",
        "dial_code": "+856",
        "code": "LA"
    },
    {
        "name": "Lettland",
        "dial_code": "+371",
        "code": "LV"
    },
    {
        "name": "Libanon",
        "dial_code": "+961",
        "code": "LB"
    },
    {
        "name": "Lesotho",
        "dial_code": "+266",
        "code": "LS"
    },
    {
        "name": "Liberia",
        "dial_code": "+231",
        "code": "LR"
    },
    {
        "name": "Libyen",
        "dial_code": "+218",
        "code": "LY"
    },
    {
        "name": "Liechtenstein",
        "dial_code": "+423",
        "code": "LI"
    },
    {
        "name": "Litauen",
        "dial_code": "+370",
        "code": "LT"
    },
    {
        "name": "Luxemburg",
        "dial_code": "+352",
        "code": "LU"
    },
    {
        "name": "Macau",
        "dial_code": "+853",
        "code": "MO"
    },
    {
        "name": "Nordmazedonien",
        "dial_code": "+389",
        "code": "MK"
    },
    {
        "name": "Madagaskar",
        "dial_code": "+261",
        "code": "MG"
    },
    {
        "name": "Malawi",
        "dial_code": "+265",
        "code": "MW"
    },
    {
        "name": "Malaysia",
        "dial_code": "+60",
        "code": "MY"
    },
    {
        "name": "Malediven",
        "dial_code": "+960",
        "code": "MV"
    },
    {
        "name": "Mali",
        "dial_code": "+223",
        "code": "ML"
    },
    {
        "name": "Malta",
        "dial_code": "+356",
        "code": "MT"
    },
    {
        "name": "Marshallinseln",
        "dial_code": "+692",
        "code": "MH"
    },
    {
        "name": "Martinique",
        "dial_code": "+596",
        "code": "MQ"
    },
    {
        "name": "Mauretanien",
        "dial_code": "+222",
        "code": "MR"
    },
    {
        "name": "Mauritius",
        "dial_code": "+230",
        "code": "MU"
    },
    {
        "name": "Mayotte",
        "dial_code": "+262",
        "code": "YT"
    },
    {
        "name": "Mexiko",
        "dial_code": "+52",
        "code": "MX"
    },
    {
        "name": "Mikronesien, Föderierte Staaten von Mikronesien",
        "dial_code": "+691",
        "code": "FM"
    },
    {
        "name": "Moldawien",
        "dial_code": "+373",
        "code": "MD"
    },
    {
        "name": "Monaco",
        "dial_code": "+377",
        "code": "MC"
    },
    {
        "name": "Mongolei",
        "dial_code": "+976",
        "code": "MN"
    },
    {
        "name": "Montenegro",
        "dial_code": "+382",
        "code": "ME"
    },
    {
        "name": "Montserrat",
        "dial_code": "+1664",
        "code": "MS"
    },
    {
        "name": "Marokko",
        "dial_code": "+212",
        "code": "MA"
    },
    {
        "name": "Mosambik",
        "dial_code": "+258",
        "code": "MZ"
    },
    {
        "name": "Myanmar",
        "dial_code": "+95",
        "code": "MM"
    },
    {
        "name": "Namibia",
        "dial_code": "+264",
        "code": "NA"
    },
    {
        "name": "Nauru",
        "dial_code": "+674",
        "code": "NR"
    },
    {
        "name": "Nepal",
        "dial_code": "+977",
        "code": "NP"
    },
    {
        "name": "Niederlande",
        "dial_code": "+31",
        "code": "NL"
    },
    {
        "name": "Niederländischen Antillen",
        "dial_code": "+599",
        "code": "AN"
    },
    {
        "name": "Neukaledonien",
        "dial_code": "+687",
        "code": "NC"
    },
    {
        "name": "Neuseeland",
        "dial_code": "+64",
        "code": "NZ"
    },
    {
        "name": "Nicaragua",
        "dial_code": "+505",
        "code": "NI"
    },
    {
        "name": "Niger",
        "dial_code": "+227",
        "code": "NE"
    },
    {
        "name": "Nigeria",
        "dial_code": "+234",
        "code": "NG"
    },
    {
        "name": "Niue",
        "dial_code": "+683",
        "code": "NU"
    },
    {
        "name": "Norfolkinsel",
        "dial_code": "+672",
        "code": "NF"
    },
    {
        "name": "Nördliche Marianen",
        "dial_code": "+1670",
        "code": "MP"
    },
    {
        "name": "Norwegen",
        "dial_code": "+47",
        "code": "NO"
    },
    {
        "name": "Oman",
        "dial_code": "+968",
        "code": "OM"
    },
    {
        "name": "Pakistan",
        "dial_code": "+92",
        "code": "PK"
    },
    {
        "name": "Palau",
        "dial_code": "+680",
        "code": "PW"
    },
    {
        "name": "Palästina",
        "dial_code": "+970",
        "code": "PS"
    },
    {
        "name": "Panama",
        "dial_code": "+507",
        "code": "PA"
    },
    {
        "name": "Papua-Neuguinea",
        "dial_code": "+675",
        "code": "PG"
    },
    {
        "name": "Paraguay",
        "dial_code": "+595",
        "code": "PY"
    },
    {
        "name": "Peru",
        "dial_code": "+51",
        "code": "PE"
    },
    {
        "name": "Philippinen",
        "dial_code": "+63",
        "code": "PH"
    },
    {
        "name": "Pitcairninseln",
        "dial_code": "+872",
        "code": "PN"
    },
    {
        "name": "Polen",
        "dial_code": "+48",
        "code": "PL"
    },
    {
        "name": "Portugal",
        "dial_code": "+351",
        "code": "PT"
    },
    {
        "name": "Puerto Rico",
        "dial_code": "+1939",
        "code": "PR"
    },
    {
        "name": "Katar",
        "dial_code": "+974",
        "code": "QA"
    },
    {
        "name": "Rumänien",
        "dial_code": "+40",
        "code": "RO"
    },
    {
        "name": "Russland",
        "dial_code": "+7",
        "code": "RU"
    },
    {
        "name": "Ruanda",
        "dial_code": "+250",
        "code": "RW"
    },
    {
        "name": "Réunion",
        "dial_code": "+262",
        "code": "RE"
    },
    {
        "name": "Saint-Barthélemy",
        "dial_code": "+590",
        "code": "BL"
    },
    {
        "name": "St. Helena, Ascension und Tristan da Cunha",
        "dial_code": "+290",
        "code": "SH"
    },
    {
        "name": "St. Kitts und Nevis",
        "dial_code": "+1869",
        "code": "KN"
    },
    {
        "name": "St. Lucia",
        "dial_code": "+1758",
        "code": "LC"
    },
    {
        "name": "St. Martin",
        "dial_code": "+590",
        "code": "MF"
    },
    {
        "name": "Saint-Pierre und Miquelon",
        "dial_code": "+508",
        "code": "PM"
    },
    {
        "name": "St. Vincent und die Grenadinen",
        "dial_code": "+1784",
        "code": "VC"
    },
    {
        "name": "Samoa",
        "dial_code": "+685",
        "code": "WS"
    },
    {
        "name": "San Marino",
        "dial_code": "+378",
        "code": "SM"
    },
    {
        "name": "Sao Tome and Principe",
        "dial_code": "+239",
        "code": "ST"
    },
    {
        "name": "Saudi Arabien",
        "dial_code": "+966",
        "code": "SA"
    },
    {
        "name": "Senegal",
        "dial_code": "+221",
        "code": "SN"
    },
    {
        "name": "Serbien",
        "dial_code": "+381",
        "code": "RS"
    },
    {
        "name": "Seychellen",
        "dial_code": "+248",
        "code": "SC"
    },
    {
        "name": "Sierra Leone",
        "dial_code": "+232",
        "code": "SL"
    },
    {
        "name": "Singapur",
        "dial_code": "+65",
        "code": "SG"
    },
    {
        "name": "Slowakei",
        "dial_code": "+421",
        "code": "SK"
    },
    {
        "name": "Slowenien",
        "dial_code": "+386",
        "code": "SI"
    },
    {
        "name": "Salomonen",
        "dial_code": "+677",
        "code": "SB"
    },
    {
        "name": "Somalia",
        "dial_code": "+252",
        "code": "SO"
    },
    {
        "name": "Südafrika",
        "dial_code": "+27",
        "code": "ZA"
    },
    {
        "name": "Südsudan",
        "dial_code": "+211",
        "code": "SS"
    },
    {
        "name": "Südgeorgien und die Südlichen Sandwichinseln",
        "dial_code": "+500",
        "code": "GS"
    },
    {
        "name": "Spanien",
        "dial_code": "+34",
        "code": "ES"
    },
    {
        "name": "Sri Lanka",
        "dial_code": "+94",
        "code": "LK"
    },
    {
        "name": "Sudan",
        "dial_code": "+249",
        "code": "SD"
    },
    {
        "name": "Suriname",
        "dial_code": "+597",
        "code": "SR"
    },
    {
        "name": "Svalbard und Jan Mayen",
        "dial_code": "+47",
        "code": "SJ"
    },
    {
        "name": "Swasiland",
        "dial_code": "+268",
        "code": "SZ"
    },
    {
        "name": "Schweden",
        "dial_code": "+46",
        "code": "SE"
    },
    {
        "name": "Syrien",
        "dial_code": "+963",
        "code": "SY"
    },
    {
        "name": "Taiwan",
        "dial_code": "+886",
        "code": "TW"
    },
    {
        "name": "Tadschikistan",
        "dial_code": "+992",
        "code": "TJ"
    },
    {
        "name": "Tansania",
        "dial_code": "+255",
        "code": "TZ"
    },
    {
        "name": "Thailand",
        "dial_code": "+66",
        "code": "TH"
    },
    {
        "name": "Osttimor",
        "dial_code": "+670",
        "code": "TL"
    },
    {
        "name": "Togo",
        "dial_code": "+228",
        "code": "TG"
    },
    {
        "name": "Tokelau",
        "dial_code": "+690",
        "code": "TK"
    },
    {
        "name": "Tonga",
        "dial_code": "+676",
        "code": "TO"
    },
    {
        "name": "Trinidad und Tobago",
        "dial_code": "+1868",
        "code": "TT"
    },
    {
        "name": "Tunesien",
        "dial_code": "+216",
        "code": "TN"
    },
    {
        "name": "Türkei",
        "dial_code": "+90",
        "code": "TR"
    },
    {
        "name": "Turkmenistan",
        "dial_code": "+993",
        "code": "TM"
    },
    {
        "name": "Turks- und Caicosinseln",
        "dial_code": "+1649",
        "code": "TC"
    },
    {
        "name": "Tuvalu",
        "dial_code": "+688",
        "code": "TV"
    },
    {
        "name": "Uganda",
        "dial_code": "+256",
        "code": "UG"
    },
    {
        "name": "Ukraine",
        "dial_code": "+380",
        "code": "UA"
    },
    {
        "name": "Vereinigte Arabische Emirate",
        "dial_code": "+971",
        "code": "AE"
    },
    {
        "name": "Vereinigtes Königreich",
        "dial_code": "+44",
        "code": "GB"
    },
    {
        "name": "USA",
        "dial_code": "+1",
        "code": "US"
    },
    {
        "name": "Uruguay",
        "dial_code": "+598",
        "code": "UY"
    },
    {
        "name": "Usbekistan",
        "dial_code": "+998",
        "code": "UZ"
    },
    {
        "name": "Vanuatu",
        "dial_code": "+678",
        "code": "VU"
    },
    {
        "name": "Venezuela",
        "dial_code": "+58",
        "code": "VE"
    },
    {
        "name": "Vietnam",
        "dial_code": "+84",
        "code": "VN"
    },
    {
        "name": "Britische Jungferninseln",
        "dial_code": "+1284",
        "code": "VG"
    },
    {
        "name": "Amerikanische Jungferninseln",
        "dial_code": "+1340",
        "code": "VI"
    },
    {
        "name": "Wallis and Futuna",
        "dial_code": "+681",
        "code": "WF"
    },
    {
        "name": "Jemen",
        "dial_code": "+967",
        "code": "YE"
    },
    {
        "name": "Sambia",
        "dial_code": "+260",
        "code": "ZM"
    },
    {
        "name": "Simbabwe",
        "dial_code": "+263",
        "code": "ZW"
    },    
    {
        "name": "Zypern",
        "dial_code": "+357",
        "code": "CY"
    },
]