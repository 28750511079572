import { Inject, Injectable } from '@angular/core';
import { FetchingService } from './fetching.service';
import { LoggerService } from './logger.service';

import { applyReactForm } from './../applyReactForm';
import { applyFormSections } from './../applyFormSections';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { InitialSource } from 'src/app/services/trackingservice.service';

export interface ApplyConfig {
  endPoint: string
}

@Injectable({
  providedIn: 'any'
})
export class ApplyService {

  constructor(
    public logger: LoggerService,
    public fetching: FetchingService,
    public sanitizer: DomSanitizer,
    @Inject('config') private config: ApplyConfig
  ) {
    this.fetching.postingConfig.endPoint = this.config.endPoint;
    // reset change state if component was revisited and page was not reloaded
    this.sections.forEach(s => s.changed = true);

    this.applyForm.valueChanges.subscribe((changes) => {
      this.logger.log("Apply Form has Change : ", changes);
      this.logger.log("Apply Form Current Section : ", this.sections[this.currentSection].control);
      this.sections[this.currentSection].changed = true;
    });
  }

  /**
     * On First Submitted a SessionsRow will be created
     * Al other Submissions after First Submission should wait for First Submission to be posted so that a sessionRow exists
     */
  readonly SESSIONS_ROW_NECESSARY_SECTION = 0;
  /**
   * On Phone Submitted a LeadsRow will be created
   * Al other Submissions after Phone should wait for Phone to be posted so that a leadsRow exists
   */
  readonly LEADS_ROW_NECESSARY_SECTION = 9; // this should match the index position of phoneNumberSection in applyFormSections

  initialSource: InitialSource = 'facebook';
  sessionTimestamp!: number;
  getSessionTimestamp(): number {
    try {
      let sessionTimestamp = parseInt(localStorage.getItem('sessionTimestamp') as string);
      if (!sessionTimestamp || this.sessionTimeExpired(sessionTimestamp)) {
        sessionTimestamp = new Date().getTime();
        localStorage.setItem('sessionTimestamp', JSON.stringify(sessionTimestamp));
      }
      return sessionTimestamp;
    } catch (error) {
      let sessionTimestamp = new Date().getTime();
      return sessionTimestamp;
    }
  }

  sessionTimeExpired(sessionTimestamp: number) {
    const startOfDay = new Date().setUTCHours(0, 0, 0, 0);
    return sessionTimestamp < startOfDay;
  }

  applyForm = applyReactForm;

  /**
   * Index for sections
   * Incrementing or decrementing will navigate the GUI to next or previous form
   * We can not use currentSection in template to access specific content of a section because,
   *  during animation two sections are simultaneously displayed.
   */
  currentSection: number = 0;
  /**
   * sections defines the order of appearance
   */
  readonly sections = applyFormSections;

  getSectionTitleByControl(control: AbstractControl) {
    const index = this.getSectionIndexByControl(control);
    return this.sections[index].title;
  }

  getSectionIndexByControl(control: AbstractControl) {
    return this.sections.findIndex(s => s.control == control);
  }

  getSectionOptionsByControl(control: AbstractControl) {
    const index = this.getSectionIndexByControl(control);
    return this.sections[index].options;
  }

  getSectionOptionsRangeByControl(control: AbstractControl, start: number, end: number) {
    const index = this.getSectionIndexByControl(control);
    return this.sections[index].options.slice(start, end);
  }

  getSectionOptionsLengthByControl(control: AbstractControl) {
    const index = this.getSectionIndexByControl(control);
    return this.sections[index].options.length;
  }

  /**
   * If sectionControl is currently viewed
   * @param sectionControl - A control that exists in sections
   */
  isCurrentSection(sectionControl: AbstractControl) {
    return this.sections[this.currentSection].control == sectionControl;
  }

  isLastSection(toPostSection: number) {
    return toPostSection == this.sections.length - 1;
  }

  isFirstSession(toPostSection: number) {
    return toPostSection == 0;
  }

  canNavigateToNextSection() {
    return this.currentSection < this.sections.length - 1 &&
      this.sections[this.currentSection].control.valid;
  }

  canPostSection(toPostSection: number) {
    return this.sections[toPostSection].changed &&
      this.sections[toPostSection].control.valid &&
      !this.sections[toPostSection].control.disabled
  }

  canPostSession(toPostSection: number) {
    return this.isLastSection(toPostSection) && this.canPostSection(toPostSection);
  }

  getPayload(toPostSection: number) {
    return this.isFirstSession(toPostSection) ? {
      session: this.sessionTimestamp,
      initialSource: this.initialSource,
      utm_source: localStorage.getItem('utm_source') || '',
      utm_medium: localStorage.getItem('utm_medium') || '',
      utm_campaign: localStorage.getItem('utm_campaign') || '',
      utm_id: localStorage.getItem('utm_id') || '',
      utm_term: localStorage.getItem('utm_term') || '',
      utm_content: localStorage.getItem('utm_content') || '',
      form: this.sections[toPostSection].control.value,
    } : {
      session: this.sessionTimestamp,
      form: this.sections[toPostSection].control.value,
    }
  }

  triggerLoadingFeedback(toPostSection: number) {
    if (this.isLastSection(toPostSection))
      this.fetching.fetching = true; // Trigger Loading Feedback
  }

  markAsSubmitted(toPostSection: number) {
    this.sections[toPostSection].submitted = true;
    this.sections[toPostSection].changed = false;
    this.sections[toPostSection].submitted$.next(true);
  }

  stopLoadingFeedback(toPostSection: number) {
    if (this.isLastSection(toPostSection))
      this.fetching.fetching = false;
  }

  collectErrors(error: any) {
    this.fetching.errors = [];
    if (error instanceof Error) {
      this.fetching.errors.push({ message: error.message, stack: error.stack });
    } else {
      this.fetching.errors.push(error);
    }
  }
}