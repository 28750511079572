import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'any'
})
export class LoggerService {

  constructor() { }

  showLogs = false;
  feedback = {
    error : false,
    message : '',
  }

  log(...args : any){
    if(this.showLogs) console.log(args);
  }

  error(...args : any){
    if(this.showLogs) console.error(args);
  }
}
