import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { LandingComponent } from './landing/landing.component';
import { ThankYouPageComponent } from './thank-you-page/thank-you-page.component';
import { ApplyModule } from './apply/apply.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';




@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LandingComponent,
    ThankYouPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,

    ApplyModule.forRoot(environment.applyConfig),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
