import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { requiredAtLeastOneSelection } from "./formcustomvalidators";

/**
 * Temporally canApply
 */
const headerForm = new FormGroup({
    accept: new FormControl(false, Validators.requiredTrue)
});

export const NO_WHITE_SPACE_REGEX = /^(\s+\S+\s*)*(?!\s).*$/;
const introductionForm = new FormGroup({
    // gender: [null, [Validators.required]],
    lastName: new FormControl(null, Validators.required),
    firstName: new FormControl(null, Validators.required),
}, { validators: [Validators.required, Validators.pattern(NO_WHITE_SPACE_REGEX)] });

const ageForm = new FormGroup({
    age: new FormControl(null, Validators.required),
});

const professionForm = new FormGroup({
    profession: new FormControl(null, Validators.required)
});

const experienceForm = new FormGroup({
    experience: new FormControl(null, Validators.required)
});

const desiresForm = new FormGroup({
    desires: new FormArray([
        new FormControl(null),
        new FormControl(null),
        new FormControl(null),
        new FormControl(null),
        new FormControl(null),
    ], requiredAtLeastOneSelection as ValidatorFn),
});

const stacksForm = new FormGroup({
    stacks: new FormArray([
        new FormControl(null),
        new FormControl(null),
        new FormControl(null)
    ], requiredAtLeastOneSelection as ValidatorFn),
});

const willForm = new FormGroup({
    will: new FormControl(null, Validators.required)

});

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailForm = new FormGroup({
    email: new FormControl(null, Validators.compose([Validators.required, Validators.pattern(EMAIL_REGEX)])),
});

const phoneNumberForm = new FormGroup({
    phoneNumber: new FormControl('+49', Validators.compose(
        [
            Validators.required,
            Validators.pattern("^\\+?([\\s\\-\\/]?\\(?[\\d]{1,16}\\)?){1,5}\\s?$"),
            Validators.minLength(5),
        ])),
});

const reachableForm = new FormGroup({
    reachable: new FormControl(null, Validators.required)
});

const currentSituationForm = new FormGroup({
    situation: new FormControl(null, Validators.required)
});

const acceptForm = new FormGroup({
    accept: new FormControl(null, Validators.requiredTrue)
});

export const applyReactForm = new FormGroup(
    {
        headerForm, // BooleanInputControl,
        introductionForm, // TextInput
        ageForm, // SingleChoiceControl
        professionForm, // SingleChoiceControl
        experienceForm, // SingleChoiceControl
        desiresForm, // MultipleChoiceControl
        stacksForm, // MultipleChoiceLastSelectsAll
        willForm, // TextAreaInputControl
        emailForm, // EmailInputControl
        phoneNumberForm, // PhoneInputControl
        reachableForm, // SingleChoiceControl
        // currentSituationForm, // SingleChoiceControl
        // acceptForm // BooleanChoiceControl
    }
);

function logAllControls() {
    for (const key in applyReactForm.controls) {
        if (Object.prototype.hasOwnProperty.call(applyReactForm.controls, key)) {
            const element = applyReactForm.controls[key];
        }
    }
}